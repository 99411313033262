import React from "react"

import Layout from "../components/blogLayout"
import SEO from "../components/seo"

class ImprintPage extends React.Component {

    render() {
        return (
            <Layout
                location={this.props.location} >
                <h1>Imprint and Data Protection</h1>

                <h2>Publisher, development and implementation</h2>
                <p>Personal project.</p>
                <p>The following information is based on the requirements of § 5 TMG.</p>
                <p>
                    Christian Konrad
                    <br/>
                    Am Dorfgarten 33
                    <br/>
                    60435 Frankfurt am Main (Germany)
                    <br/>
                    E-Mail: office@christian-konrad.me
                </p>
                <p>All content and services on this website are provided by the owner of the website.</p>

                <h2>Liability for and review of content</h2>
                <p>By the specifications in § 7 paragraph 1 TMG I am responsible as webmaster for the content of my blog. At the same time, §§ 8 up to and including § 10 TMG exempt me from the responsibility to monitor transmitted or stored third-party content. Nevertheless, I am aware of my duty to comply with the blocking and removal of information as required by applicable laws.</p>
            </Layout>
        );
    }
}

export default ImprintPage
